<script>
import VxDialogView from "@/components/vx/VxDialogView";
import RaiDatePicker from "@/components/util/RaiDatePicker.vue";
import RaiTimePickerText from "@/components/util/RaiTimePickerText.vue";
import RaiEmployeeSelect from "@/components/util/RaiEmployeeSelect.vue";

import { chain } from "@/utils/lib";
import { validationMixin } from "vuelidate";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import { required, minLength } from "vuelidate/lib/validators";
import { STORE_EMPLOYEES } from "@/graphql/employee/queries.gql";
import { CREATE_TODO } from "@/graphql/todo/mutations.gql";
import { ACTIVE_TASK_LISTS } from "@/graphql/task/queries.gql";
import { get, pickBy } from "lodash";
import { mapGetters } from "vuex";

import {
  parseISO,
  format,
  addDays,
  isBefore,
  isSameDay,
  endOfDay,
  setYear,
  setDate,
  setMonth,
  getYear,
  getDate,
  getMonth,
} from "date-fns";

export default {
  name: "NewTaskDialog",
  components: {
    VxDialogView,
    RaiEmployeeSelect,
    RaiDatePicker,
    RaiTimePickerText,
  },
  mixins: [hasSnackbarAccess, validationMixin],
  props: {},
  data() {
    return {
      employee: null,
      name: "",
      isManagerOnly: false,
      loading: false,
      valid: false,
      dueTime: null,
      dueDate: null,
      localStartDate: null,
      isDifferentDay: false,
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(1),
    },
  },
  apollo: {
    employees: {
      query: STORE_EMPLOYEES,
      variables() {
        return {
          storeId: this.$route.params.storeId,
        };
      },
    },
  },
  computed: {
    ...mapGetters("bizdate", ["getBizDate"]),
    titleText() {
      if (get(this.employee, "fullName"))
        return `Add a task for ${this.employee.fullName}`;
      return "Add a task";
    },
    // The current date, formatted
    businessDate() {
      return format(new Date(), "yyyy-MM-dd");
    },
    // Returns an object used as input for creating a todo
    variables() {
      // Only send keys which have a value
      const input = pickBy(
        {
          businessDate:
            (this.isDifferentDay && this.startDate) || this.businessDate,
          name: this.name,
          isManagerOnly: this.isManagerOnly,
          dueAt: this.dueAt,
          employeeId: get(this.employee, "id"),
        },
        // If the value is `undefined` or `null`,
        // don't include it in the object
        (v, k) => ![undefined, null].includes(v)
      );
      return { input };
    },
    // The date the todo should start showing
    startDate: {
      get() {
        return (
          this.localStartDate ||
          format(parseISO(this.businessDate), "yyyy-MM-dd")
        );
      },
      set(v) {
        this.localStartDate = v;
      },
    },
    dueAt() {
      if (!this.dueDate && !this.dueTime) return null;
      if (this.dueDate && !this.dueTime)
        return endOfDay(parseISO(this.dueDate));

      // If there is a dueDate set, use it.
      // If there is no dueDate set and dueTime is in the past, use tomorrow.
      // Otherwise, use now.
      // const now = new Date();
      // const date =
      //   this.dueDate ||
      //   (isBefore(this.dueTime, now) && addDays(now, 1)) ||
      //   new Date();

      // Set the date on `dueTime`
      return chain(this.dueTime)
        .do(setYear, getYear(parseISO(this.dueDate)))
        .do(setMonth, getMonth(parseISO(this.dueDate)))
        .do(setDate, getDate(parseISO(this.dueDate)))
        .value();
    },
    isValid() {
      return !this.$v.$invalid;
    },
  },
  watch: {
    name(v) {
      if (v && v.length > 0) {
        return (this.valid = true);
      }
      this.valid = false;
    },
    dueTime(v) {
      if (!!v) this.calculateDueDate(v);
    },
  },
  mounted() {
    var date = new Date();
    date.setHours(23, 59, 59);
    this.dueTime = date;
    this.dueAt = date;
  },
  methods: {
    addTask(close = true) {
      // store `this` for use in `update()` hook
      const vm = this;
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: CREATE_TODO,
          variables: { ...this.variables },
          update(
            store,
            {
              data: {
                createTodo: { todo },
              },
            }
          ) {
            if (
              !todo.businessDate ||
              (todo.businessDate &&
                !isSameDay(
                  parseISO(todo.businessDate),
                  parseISO(vm.businessDate)
                ))
            )
              return true;
            const query = ACTIVE_TASK_LISTS;
            const variables = {
              storeId: vm.$route.params.storeId,
              businessDate: vm.businessDate,
            };
            const data = store.readQuery({ query, variables });
            data.todos.push(todo);
            store.writeQuery({ query, variables, data });
          },
        })
        // This value will be passed through the promise chain.
        // It is ultimately used to determine whether the
        // action succeeded or failed.
        // If it is true by the final link in the chain,
        // we can hide the dialog. Otherwise, we want
        // the dialog to stay visible.
        .then(() => true)
        .catch((error) => {
          this.showSnackbar({
            text: `An error occurred while creating the task. Error: ${error}`,
          });
          return false;
        })
        // If the catch block ran, `bool` will be false.
        // Otherwise, it will be whatever it was assigned
        // in the first `then` function. (in this case, `true`)
        .then((bool) => {
          this.loading = false;
          if (bool) {
            this.resetFields();
            if (close) this.$router.go(-1);
            this.showSnackbar({
              text: `Task created successfully`,
            });
          }
        });
    },
    resetFields() {
      this.name = "";
      this.employee = null;
      this.isManagerOnly = false;
      this.valid = false;
      this.dueTime = null;
      this.dueDate = null;
      this.startDate = null;
      this.isDifferentDay = false;
    },
    calculateDueDate(time) {
      const now = new Date();

      if (isBefore(time, now)) {
        this.dueDate = format(addDays(now, 1), "yyyy-MM-dd");
        return;
      }

      this.dueDate = this.dueDate || format(new Date(), "yyyy-MM-dd");
    },
    onEmployeeClear() {
      this.employee = undefined;
    },
  },
};
</script>

<template>
  <VxDialogView data-testid="newTaskDialog" :title="titleText">
    <v-container>
      <v-form @submit.prevent="addTask">
        <v-row class="align-center" no-gutters>
          <span class="shrink">Assign an employee</span>
          <RaiEmployeeSelect
            v-model="employee"
            data-testid="newTaskDialogRaiEmployeeSelectField"
            clearable
            :employees="employees"
            :hide-search="false"
            @clear="onEmployeeClear"
          />
        </v-row>
        <v-row no-gutters>
          <v-text-field
            v-model="name"
            name="name"
            label="Task description"
            autofocus
            clearable
            autocomplete="please-dont"
            data-testid="newTaskDialogDescriptionField"
          />
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" class="py-0">
            <rai-date-picker
              v-model="dueDate"
              label="Due date"
              :min="businessDate"
              data-testid="newTaskDialogDueDateField"
            />
          </v-col>
          <v-col class="py-0">
            <rai-time-picker-text
              v-model="dueTime"
              class="ml-4"
              clearable
              label="Due time"
              data-testid="newTaskDialogDueTimeField"
            />
          </v-col>
        </v-row>
        <v-row justify="start" no-gutters>
          <v-checkbox
            v-model="isDifferentDay"
            class="shrink mr-4"
            label="This task is for another day"
            data-testid="newTaskDialogDifferentDayCheckbox"
            hide-details
          />
          <rai-date-picker
            v-if="isDifferentDay"
            v-model="startDate"
            label="Start date"
            hint="When should this task show up?"
            data-testid="newTaskDialogStartDateField"
            persistent-hint
            :min="businessDate"
          />
        </v-row>
        <v-checkbox v-model="isManagerOnly" label="Manager only" />
      </v-form>
      <v-row class="mx-2 justify-end">
        <v-btn
          :loading="loading"
          :disabled="!isValid"
          data-testid="newTaskDialogSaveBtn"
          text
          color="primary"
          @click="addTask"
          v-text="`Save`"
        />
        <v-btn
          :loading="loading"
          :disabled="!isValid"
          text
          @click="addTask(false)"
          v-text="`Save and add another`"
        />
      </v-row>
    </v-container>
  </VxDialogView>
</template>

<style></style>
